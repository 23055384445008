import { Injectable } from '@angular/core';
import { ApiConfiguration } from 'src/app/services/http/api-configuration';
import { ApiService } from 'src/app/services/http/api.service';
import { ToastClientService } from 'src/app/services/toast/toast-client.service';
import { RewardCategoryModel, RewardSubCategoryModel, RewardTypeModel } from '../models/rewards';

@Injectable({
  providedIn: 'root'
})
export class RewardProvider {

  rewardCategory: RewardCategoryModel[] = [];
  rewardSubCategories: RewardSubCategoryModel[] = [];
  rewardTypes: RewardTypeModel[] = [];
  rewardParentCategories = [];
  rewardHistory = [];
  constructor(
    private apiService: ApiService,
    private toast: ToastClientService,
    private apiConfig: ApiConfiguration
  ) {
  }

  getRewardCategoryList(): void {
    // if (this.rewardCategory.length == 0) {
    this.apiService.get(this.apiConfig.getRewardCategories).subscribe(
      (res: any) => {
        this.rewardCategory = res.data.reward_categories as RewardCategoryModel[];
        //
      }
    );
    //}
  }

  addRewardCategoryList(formData): void {
    this.apiService.post(this.apiConfig.addRewardCategories, formData).subscribe(
      (res: any) => {
        if (!formData.id) {
          this.rewardCategory.push(formData);
        }
        this.rewardCategory.forEach(b => {
          if (b.id == formData.id) {
            b.category_name = formData.category_name;
            b.description = formData.description;
            b.hassubcategories = formData.hassubcategories;
            b.category_order = formData.category_order;
          }
        })
        let updateIndex = this.rewardCategory.findIndex(item => item.id == formData.id);
        this.rewardCategory[updateIndex] = formData;
        this.toast.Success(res.body.data.message);
      }
    );

  }

  editRewardCategoryList(formData): void {
    this.apiService.post(this.apiConfig.editRewardCategories, formData).subscribe(
      (res: any) => {
        let updateIndex = this.rewardCategory.findIndex(item => item.id == formData.id);
        this.rewardCategory[updateIndex] = formData;
        this.toast.Success(res.body.data.message);
      }, err => {
        // this.toast.Success(res.body.data.message);
      }
    );
  }

  deleteRewardCategoryList(formData): void {
    this.apiService.post(this.apiConfig.deleteRewardCategories, formData).subscribe(
      (res: any) => {
        let updateIndex = this.rewardCategory.findIndex(item => item.id == formData.id);
        this.rewardCategory.splice(updateIndex, 1);
        this.toast.Success(res.body.data.message);
      }, err => {
        // this.toast.Success(res.body.data.message);
      }
    );
  }

  //REWARD SUB CATEGORIES
  getRewardSubCategory(): void {
    if (this.rewardCategory.length == 0) {
      this.apiService.get(this.apiConfig.getRewardSubCategories).subscribe(
        (res: any) => {
          //
          this.rewardSubCategories = res.data.reward_sub_categories as RewardSubCategoryModel[];
          this.rewardParentCategories = res.data.reward_categories
        }
      );
    }
  }
  getList() {
    this.apiService.get(this.apiConfig.getRewardSubCategories).subscribe(
      (res: any) => {
        //
        this.rewardSubCategories = res.data.reward_sub_categories as RewardSubCategoryModel[];
        this.rewardParentCategories = res.data.reward_categories
      }
    );
  }

  addRewardSubCategoryList(formData): void {
    this.apiService.post(this.apiConfig.addRewardSubCategories, formData).subscribe(
      (res: any) => {
        //this.getList();
        // if (!formData.id) {
        //   this.rewardSubCategories.push(formData);
        // }
        // this.rewardSubCategories.forEach(b => {
        //   if (b.id == formData.id) {
        //     b.sub_category_name = formData.sub_category_name;
        //     b.description = formData.description;
        //     b.category_id = formData.category_id;
        //   }
        // })
        // this.toast.Success(res.body.data.message);
        // this.getRewardSubCategory();
        this.toast.Success("Sub Category Added");
      }
    );

  }

  editRewardSubCategoryList(formData): void {
    this.apiService.post(this.apiConfig.editRewardSubCategories, formData).subscribe(
      (res: any) => {
        let updateIndex = this.rewardSubCategories.findIndex(item => item.id == formData.id);
        this.rewardSubCategories[updateIndex] = formData;
        // this.rewardSubCategories[updateIndex].category_name = formData.category_name;
        // this.toast.Success(res.body.data.message);
        // this.toast.Success(res.body.data.message);
        // this.getRewardSubCategory();
        this.getList();
        this.toast.Success("Sub Category Updated");
      }
    );
  }

  deleteRewardSubCategoryList(formData): void {
    this.apiService.post(this.apiConfig.deleteRewardSubCategories, formData).subscribe(
      (res: any) => {
        let updateIndex = this.rewardSubCategories.findIndex(item => item.id == formData.id);
        this.rewardSubCategories.splice(updateIndex, 1);
        this.toast.Success(res.body.data.message);
        this.getRewardSubCategory();
      }, err => {
        // this.toast.Success(res.body.data.message);
      }
    );
  }

  //REWARD History
  getRewardHistory(): void {
    if (this.rewardHistory.length == 0) {
      this.apiService.get(this.apiConfig.rewardHistory).subscribe(
        (res: any) => {
          this.rewardHistory = res.data.reward_orders;
        }
      );
    }
  }

  /*Reward Type*/
  getRewardTypeList(): void {
    if (this.rewardTypes.length == 0) {
      this.apiService.get(this.apiConfig.getRewardType).subscribe(
        (res: any) => {
          this.rewardTypes = res.data as RewardTypeModel[];
        }
      );
    }
  }

  addRewardType(formData: RewardTypeModel) {
    let apiUrl = this.apiConfig.addRewardType;
    this.apiService.post(apiUrl, formData).subscribe(
      (res: any) => {
        formData.id = res.body.data.id;

        this.rewardTypes.push(formData);
        this.toast.Success(res.body.data.message);
      },
      (err) => {
        this.toast.Error(err.body.data.message);
      }
    );
  }

  editRewardType(formData: RewardTypeModel) {
    let apiUrl = this.apiConfig.editRewardType + formData.id;
    // 
    this.apiService.post(apiUrl, formData)
      .subscribe((res: any) => {
        let updateIndex = this.rewardTypes.findIndex(item => item.id == formData.id);
        this.rewardTypes[updateIndex].reward_type_name = formData.reward_type_name;
        this.rewardTypes[updateIndex].reward_type_icon = formData.reward_type_icon;
        this.toast.Success(res.body.data.message);
      },
        (err) => {
          this.toast.Error(err.body.data.message);
        }
      );
  }

  deleteRewardType(formData: RewardTypeModel) {
    let apiUrl = this.apiConfig.editRewardType + formData.id;
    this.apiService.post(apiUrl, formData)
      .subscribe((res: any) => {
        let updateIndex = this.rewardTypes.findIndex(item => item.id == formData.id);
        this.rewardTypes.splice(updateIndex, 1);
        this.toast.Success(res.body.data.message);
      },
        (err) => {
          this.toast.Error(err.body.data.message);
        }
      );
  }

}